<template>
    <v-dialog v-model="isOpen" max-width="900px" persistent>
        <v-card>
            <v-card-title class="pa-4">
                <v-btn 
                    @click.stop="cerrarModal"
                    style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                    class="pt-0"
                    text
                    rounded
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-4">
                <p>
                    Por lo que se considerará beneficiario final:
                    a) La persona natural por cuya cuenta se pretenda establecer una relación contractual o intervenir en cualquier operación.
                    b) La persona natural que en último término posea o controle directa o indirectamente un porcentaje igual o superior al diez por ciento (10%) 
                    del capital o de los derechos de voto de una persona jurídica, o que por otros medios ejerzan el control directo o indirecto, de la gestión de 
                    una persona jurídica.
                </p>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: 'ModalInformacionBeneficiarioFinal',
    props: {
        isOpen: Boolean,
    },
    methods: {
        cerrarModal() {
            this.$emit("update:isOpen", false);
        },
    },
}
</script>