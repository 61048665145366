<template>
    <v-card style="height: 100%;">
        <v-card-title class="justify-space-between flex-wrap" style="gap: 16px; position: relative;">
            <h6 class="text-subtitle-2 font-weight-bold text-uppercase">
                <v-icon class="mr-1">mdi-account-tie</v-icon>
                Beneficiario final
            </h6>
            <div 
                :style="`${$vuetify.breakpoint.mdAndUp ? 'position: absolute; right: 16px;' : ''}; gap: 8px; cursor: pointer;`" 
                class="d-flex align-center flex-wrap" 
                @click.stop="dialogoBeneficiarioFinal = true"
            >
                <p class="mb-0 text-uppercase font-weight-bold text-caption">¿Qué es un beneficiario final?</p>
                <v-btn 
                    style="min-width: unset !important; min-height: unset !important; width: 30px !important; height: 30px !important;" 
                    class="pt-0"
                    color="secondary"
                    rounded
                >
                    <v-icon color="white" size="16px">mdi-help</v-icon>
                </v-btn>
            </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-4">
            <v-alert type="warning" text dense>
                Este apartado es requerido para la verificación del perfil
            </v-alert>
            <v-form class="my-4">
                <v-autocomplete 
                    v-model="formulario.id_pais"
                    :items="paises.data"
                    :loading="paises.isLoading"
                    item-text="gentilicio"
                    item-value="id"
                    label="Nacionalidad"
                    placeholder="Indique su nacionalidad"
                    outlined
                    hide-details
                />
                <v-select 
                    v-model="formulario.id_tipo_documento"
                    :items="tiposContribuyentesFiltrados"
                    :disabled="!this.formulario.id_pais"
                    item-text="nombre"
                    item-value="id"
                    label="Tipo de documento"
                    placeholder="Seleccione un tipo documento"
                    class="mt-4"
                    outlined
                    hide-details
                />

                <v-text-field 
                    v-if="formulario.id_tipo_documento === 1"
                    v-model="formulario.numero_documento"
                    label="Documento"
                    placeholder="Documento"
                    outlined
                    hide-details
                    class="flex-grow-1 input-with-append mt-4"
                    v-mask="'########-#'"
                    :disabled="!formulario.id_tipo_documento"
                >
                    <template v-slot:append>
                        <v-btn
                            :loading="verificarDuiLoading"
                            :disabled="!formulario.numero_documento"
                            class="btnGreen white--text"
                            outlined
                            @click="verificarDUI"
                        >
                            Verificar DUI
                        </v-btn>
                    </template>
                </v-text-field>

                <v-text-field 
                    v-else
                    v-model="formulario.numero_documento"
                    label="Documento"
                    placeholder="Documento"
                    outlined
                    hide-details
                    class="flex-grow-1 input-with-append mt-4"
                    :disabled="!formulario.id_tipo_documento"
                >
                    <template v-slot:append>
                        <v-btn
                            class="btnGreen white--text"
                            :loading="verificacionOtrosDocumentos.isLoading"
                            :disabled="!formulario.numero_documento"
                            outlined  
                            @click="verificarPersonasOtrosDocumentos"
                        >
                            Verificar
                        </v-btn>
                    </template>
                </v-text-field>

                <div v-if="persona" class="my-4 pa-4" style="border: dashed 2px #313945;">
                    <p class="text-body-2 font-weight-bold mb-0">
                        <v-icon>mdi-account</v-icon>
                        Persona encontrada
                    </p>
                    <span class="text-body-2 mt-1 d-inline-block">{{ obtenerNombreCompletoPersona(persona) }}</span>
                </div>

                <div v-if="documentoVerificado" class="mt-4 pa-4" style="border: dashed 2px #313945;">
                    <p class="text-body-2 font-weight-bold mb-0">
                        <v-icon>mdi-account-box</v-icon>
                        Documento verificado
                    </p>
                    <span class="text-body-2 mt-1 d-inline-block">{{ documentoVerificado }}</span>
                </div>

                <template v-if="formulario.id_tipo_documento && formulario.id_tipo_documento !== 1 && documentoVerificado && !persona">
                    <v-row class="mt-4" dense>
                        <v-col cols="12" lg="4">
                            <v-text-field 
                                v-model="formulario.primer_nombre"
                                placeholder="Ingrese un nombre"
                                label="Primer nombre"
                                outlined
                                :error-messages="primerNombreErrors"
                                :hide-details="primerNombreErrors.length === 0"
                                @input="$v.formulario.primer_nombre.$touch()"
                                @blur="$v.formulario.primer_nombre.$touch()"
                            />
                        </v-col>

                        <v-col cols="12" lg="4">
                            <v-text-field 
                                v-model="formulario.segundo_nombre"
                                placeholder="Ingrese un nombre"
                                label="Segundo nombre"
                                outlined
                                hide-details
                            />
                        </v-col>

                        <v-col cols="12" lg="4">
                            <v-text-field 
                                v-model="formulario.tercer_nombre"
                                placeholder="Ingrese un nombre"
                                label="Tercer nombre"
                                outlined
                                hide-details
                            />
                        </v-col>
                    </v-row>

                    <v-row class="mt-4" dense>
                        <v-col cols="12" lg="4">
                            <v-text-field 
                                v-model="formulario.primer_apellido"
                                placeholder="Ingrese un apellido"
                                label="Primer apellido"
                                outlined
                                :error-messages="primerApellidoErrors"
                                :hide-details="primerApellidoErrors.length === 0"
                                @input="$v.formulario.primer_apellido.$touch()"
                                @blur="$v.formulario.primer_apellido.$touch()"
                            />
                        </v-col>

                        <v-col cols="12" lg="4">
                            <v-text-field 
                                v-model="formulario.segundo_apellido"
                                placeholder="Ingrese un apellido"
                                label="Segundo apellido"
                                outlined
                                hide-details
                            />
                        </v-col>

                        <v-col cols="12" lg="4">
                            <v-text-field 
                                v-model="formulario.apellido_casada"
                                placeholder="Ingrese un apellido"
                                label="Apellido de casada"
                                outlined
                                hide-details
                            />
                        </v-col>
                    </v-row>

                    <v-row class="mt-4" dense>
                        <v-col cols="12" lg="4">
                            <v-select 
                                v-model="formulario.id_genero"
                                :items="generos"
                                :loading="cargandoGeneros"
                                placeholder="Género"
                                label="Género"
                                item-text="nombre"
                                item-value="id"
                                outlined
                                :error-messages="idGeneroErrors"
                                :hide-details="idGeneroErrors.length === 0"
                                @change="$v.formulario.id_genero.$touch()"
                            />
                        </v-col>
                        <v-col cols="12" lg="4">
                            <v-text-field 
                                v-model="formulario.telefono"
                                placeholder="Teléfono"
                                label="Teléfono"
                                outlined
                                :error-messages="telefonoErrors"
                                :hide-details="telefonoErrors.length === 0"
                                @input="$v.formulario.telefono.$touch()"
                                @blur="$v.formulario.telefono.$touch()"
                            />
                        </v-col>
                    </v-row>

                    <v-textarea 
                        v-model="formulario.direccion"
                        placeholder="Dirección"
                        label="Dirección"
                        class="mt-4"
                        outlined
                        :error-messages="direccionErrors"
                        :hide-details="direccionErrors.length === 0"
                        @input="$v.formulario.direccion.$touch()"
                        @blur="$v.formulario.direccion.$touch()"
                    />
                </template>

                <v-btn 
                    @click.stop="validarEnvioFormulario" 
                    large 
                    block
                    color="primary"
                    class="mt-4" 
                    :disabled="!formulario.id_tipo_documento" 
                    :loading="guardado.isLoading"
                >
                    Guardar
                </v-btn>
            </v-form>

            <EmptyComponent 
                :empty="beneficiario.isDirty && !beneficiario.data && !beneficiario.isLoading" 
                text="No hay un beneficiario asignado"
                icon="mdi-account-tie"
                colored
            >
                <span v-if="beneficiario.isLoading" class="font-weight-bold text-caption">Cargando beneficiario...</span>

                <div v-if="beneficiario.data" class="elevation-2 mt-4">
                    <p class="text-body-2 mb-0 font-weight-bold pa-4">
                        <v-icon>mdi-account</v-icon>
                        Beneficiario actual
                    </p>

                    <v-divider />
                    <div class="pa-4">
                        <p class="text-body-2 mt-2 mb-0">
                            {{ obtenerNombreCompletoPersona(beneficiario.data.persona) }}
                        </p>
                        <span class="text-caption font-weight-bold">{{ beneficiario.data.pais.gentilicio }}</span>
                    </div>
                </div>
            </EmptyComponent>
        </v-card-text>

        <ModalInformacionBeneficiarioFinal :is-open.sync="dialogoBeneficiarioFinal" />
        <ConfirmationModalComponent 
            :isOpen="dialogoConfirmacionBeneficiario" 
            description="¿Desea guardar el beneficiario?" 
            @confirm="guardarBeneficiario" 
            @cancel="dialogoConfirmacionBeneficiario = false"  
            :isLoading="guardado.isLoading"
        />
    </v-card>
</template>
<script>
import EmptyComponent from '@/components/utils/EmptyComponent.vue';
import { obtenerNombreCompletoPersona } from '@/utils/data';
import { createLoadable, isResponseSuccesful, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import ModalInformacionBeneficiarioFinal from './ModalInformacionBeneficiarioFinal.vue';
import { required } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/form-validation';
import ConfirmationModalComponent from '@/components/utils/ConfirmationModalComponent.vue';

const estadoInicialFormulario = {
    id_pais: null,
    id_tipo_documento: null,
    numero_documento: null,
    // Creacion persona
    primer_nombre: null,
    segundo_nombre: null,
    tercer_nombre: null,
    primer_apellido: null,
    segundo_apellido: null,
    apellido_casada: null,
    id_genero: null,
    direccion: null,
    telefono: null,
}

export default {
    name: 'BeneficiarioFinal',
    components: { EmptyComponent, ModalInformacionBeneficiarioFinal, ConfirmationModalComponent },
    validations() {
        let validacionesExtras = {};
        
        if (this.formulario.id_tipo_documento && this.formulario.id_tipo_documento !== 1 && !this.persona) {
            validacionesExtras = {
                primer_nombre: { required },
                primer_apellido: { required },
                numero_documento: { required },
                id_genero: { required },
                direccion: { required },
                telefono: { required },
            }
        }

        return ({
            formulario: {
                id_tipo_documento: { required },
                id_pais: { required },
                ...validacionesExtras,
            }
        });
    },
    data: () => ({
        cargandoTiposContribuyente: false,
        tiposContribuyente: [],
        
        cargandoGeneros: false,
        generos: [],

        paises: createLoadable([]),
        // Data
        beneficiario: createLoadable(null),
        guardado: createLoadable(null),
        verificacionOtrosDocumentos: createLoadable(null),
        documentoVerificado: null,
        formulario: {
            ...estadoInicialFormulario,
        },
        persona: null,
        // VERIFICACION DUI
        verificarDuiLoading: false,
        duiVerificado: false,
        dialogoBeneficiarioFinal: false,
        dialogoConfirmacionBeneficiario: false,
    }),
    computed: {
        tiposContribuyentesFiltrados() {
            return this.formulario.id_pais === 68 
            ? this.tiposContribuyente.filter((tipo) => tipo.id === 1) 
            : this.tiposContribuyente.filter((tipo) => tipo.id !== 1)
        },
        idPaisErrors() {
            if (!this.$v.formulario?.id_pais) return [];

            return new Validator(this.$v.formulario.id_pais).detect().getResult();
        },
        primerNombreErrors() {
            if (!this.$v.formulario?.primer_nombre) return [];

            return new Validator(this.$v.formulario.primer_nombre).detect().getResult();
        },
        primerApellidoErrors() {
            if (!this.$v.formulario?.primer_apellido) return [];

            return new Validator(this.$v.formulario.primer_apellido).detect().getResult();
        },
        numeroDocumentoErrors() {
            if (!this.$v.formulario?.numero_documento) return [];

            return new Validator(this.$v.formulario.numero_documento).detect().getResult();
        },
        idGeneroErrors() {
            if (!this.$v.formulario?.id_genero) return [];

            return new Validator(this.$v.formulario.id_genero).detect().getResult();
        },
        direccionErrors() {
            if (!this.$v.formulario?.direccion) return [];

            return new Validator(this.$v.formulario.direccion).detect().getResult();
        },
        telefonoErrors() {
            if (!this.$v.formulario?.telefono) return [];

            return new Validator(this.$v.formulario.telefono).detect().getResult();
        },
    },
    methods: {
        obtenerNombreCompletoPersona,
        // UI
        validarEnvioFormulario() {
            if (this.formulario.id_tipo_documento === 1 && !this.persona) {
                this.pushAppMessage({ type: "error", message: 'Debe verificar el documento.' });
                return;
            }

            if (this.formulario.id_tipo_documento !== 1 && !this.documentoVerificado) {
                this.pushAppMessage({ type: "error", message: 'Debe verificar el documento.' });
                return;
            }

            this.$v.formulario.$touch();

            if (this.$v.formulario.$invalid) return;

            this.dialogoConfirmacionBeneficiario = true;
        },
        reiniciarFormulario() {
            this.formulario = { ...this.estadoInicialFormulario };
            this.$v.formulario.$reset();
            this.persona = null;
            this.documentoVerificado = null;
        },
        // Data
        async cargarBeneficiario() {
            toggleLoadable(this.beneficiario);
            const { data } = await this.services.Proveedores.cargarBeneficiarioFinal();
            setLoadableResponse(this.beneficiario, data);    
        },
        async guardarBeneficiario() {
            toggleLoadable(this.guardado);
            const payload = {
                ...this.formulario.id_tipo_documento === 1 
                ? ({ id_persona: this.persona.id, id_pais: this.formulario.id_pais })
                : ({ ...this.formulario, numero_documento: this.documentoVerificado })
            }

            const { data } = await this.services.Proveedores.guardarBeneficiarioFinal(payload);
            setLoadableResponse(this.guardado, data, { skipOnSuccess: true, showAlertOnSuccess: true });  
            this.dialogoConfirmacionBeneficiario = false;

            if (isResponseSuccesful(data)) {
                this.cargarBeneficiario();
                this.reiniciarFormulario();
            }
        },
        async verificarDUI() {
            try {

                if (this.formulario.numero_documento === null) return;
                
                this.verificarDuiLoading = true;

                const response = await this.services.VerificarDocumento.verificarDocumento(this.formulario.numero_documento);

                if (response.status !== 200) return;

                const { data } = response;

                this.duiVerificado = true;
                this.persona = data;
            } finally {
                this.verificarDuiLoading = false
            }
        },
        async cargarTiposContribuyente() {
            try {
                this.cargandoTiposContribuyente = true;
                const filtros = { id_tipo_contribuyente: 1 };
                const response = await this.services.IdentificadorFiscal.getIdentificadorFiscal(filtros);
                this.tiposContribuyente = response.data;
            } finally {
                this.cargandoTiposContribuyente = false;
            }
        },
        async cargarGeneros() {
            try {
                this.cargandoGeneros = true;
                const response = await this.services.Generos.getGenerosList();

                if (response.status !== 200) return;
                
                this.generos = response.data;
            } finally {
                this.cargandoGeneros = false;
            }
        },
        async cargarNacionalidades() {
            toggleLoadable(this.paises);
            const { data } = await this.services.Proveedores.cargarCatalogoPaises();
            setLoadableResponse(this.paises, data);
        },
        async verificarPersonasOtrosDocumentos() {
            toggleLoadable(this.verificacionOtrosDocumentos);
            const payload = { 
                numero_documento: this.formulario.numero_documento,
                id_tipo_documento: this.formulario.id_tipo_documento,
            }
            const { data } = await this.services.Proveedores.verificarPersonaPorDocumento(payload);
            setLoadableResponse(this.verificacionOtrosDocumentos, data, { skipOnSuccess: true });

            if (isResponseSuccesful(data)) {
                this.documentoVerificado = this.formulario.numero_documento;
                this.persona = data.data;
            }
        },
    },
    watch: {
        'formulario.id_pais'() {
            this.formulario.id_tipo_documento = null;
        },
        'formulario.id_tipo_documento'() {
            this.persona = null;
            this.documentoVerificado = null;
            this.formulario.numero_documento = null;
        },
    },
    created() {
        this.cargarTiposContribuyente();
        this.cargarBeneficiario();
        this.cargarGeneros();
        this.cargarNacionalidades();
    },
}
</script>
<style scoped>
:deep(.v-input.input-with-append) {
  align-items: center;
}

:deep(.v-input.input-with-append .v-input__append-inner) {
  margin: 10px 0 0 0 !important;
}
</style>
